"use client";
import { FC } from "react";

import Social from "@/components/social";
import BuyMeCoffee from "@/components/buy-me-coffee";
import projectsJSON from "@/json/projects.json";
import Intro from "@/components/intro";
import Project from "@/components/project";
import Figma from "@/components/figma";
import DarkVader from "@/components/dark-vader";
import { motion } from "framer-motion";
import { childStaggerVariants, parentStaggerVariants } from "@/utils/misc";

import coverDesignSystem from "/public/design-system/cover.webp";
import coverPlayerAccessibility from "/public/player/cover.webp";
import coverMobileUpload from "/public/mobile-upload/cover.webp";
import coverReadingExperience from "/public/reading-experience/cover.webp";
import coverFILWSCA from "/public/filwsca/cover.webp";
import Pop from "@/components/pop";

const ViewDesktop: FC = () => {
  return (
    <div className="hidden md:block">
      <div className="grid grid-cols-2 gap-8">
        {/* left */}
        <motion.div
          variants={parentStaggerVariants}
          initial="hidden"
          animate="visible"
          className="flex flex-col gap-8"
        >
          <motion.div variants={childStaggerVariants}>
            <Intro />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Pop />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Project
              json={projectsJSON.designSystem}
              key={projectsJSON.designSystem.title}
              img={coverDesignSystem}
            />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Figma />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Project
              json={projectsJSON.mobileUpload}
              key={projectsJSON.mobileUpload.title}
              className="pe-8"
              img={coverMobileUpload}
            />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Social />
          </motion.div>
        </motion.div>

        {/* right */}
        <motion.div
          variants={parentStaggerVariants}
          initial="hidden"
          animate="visible"
          className="flex flex-col gap-8"
        >
          <motion.div variants={childStaggerVariants}>
            <Project
              isNew
              json={projectsJSON.filwsca}
              key={projectsJSON.filwsca.title}
              className="p-8"
              img={coverFILWSCA}
            />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <DarkVader />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Project
              json={projectsJSON.playerAccessibility}
              key={projectsJSON.playerAccessibility.title}
              className="p-8"
              img={coverPlayerAccessibility}
            />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <Project
              json={projectsJSON.readingExperience}
              key={projectsJSON.readingExperience.title}
              className="pe-8"
              img={coverReadingExperience}
            />
          </motion.div>

          <motion.div variants={childStaggerVariants}>
            <BuyMeCoffee />
          </motion.div>

          <motion.div variants={childStaggerVariants}></motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ViewDesktop;
